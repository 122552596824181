export const EVENTS = {
  onboardingStarted: 'OnboardingStarted',
  onboardingStartedSubmitted: 'OnboardingStartedSubmitted',
  onboardingEnterEmailShown: 'OnboardingEnterEmailShown',
  onboardingEnterEmailSubmitted: 'OnboardingEnterEmailSubmitted',
  signupSuccess: 'AccountCreated',
  onboardingEnterNameShown: 'OnboardingEnterNameShown',
  onboardingEnterNameSubmitted: 'OnboardingEnterNameSubmitted',
  onboardingCrashDetection: 'OnboardingCrashDetection',
  onboardingCrashDetectionSubmitted: 'OnboardingCrashDetectionSubmitted',
  onboardingSpeedControl: 'OnboardingSpeedControl',
  onboardingSpeedControlSubmitted: 'OnboardingSpeedControlSubmitted',
  onboardingFallDetection: 'OnboardingFallDetection',
  onboardingFallDetectionSubmitted: 'OnboardingFallDetectionSubmitted',
  onboardingPlaceAlerts: 'OnboardingPlaceAlerts',
  onboardingPlaceAlertsSubmitted: 'OnboardingPlaceAlertsSubmitted',
  onboardingLocationHistory: 'OnboardingLocationHistory',
  onboardingLocationHistorySubmitted: 'OnboardingLocationHistorySubmitted',
  onboardingSos: 'OnboardingSos',
  onboardingSosSubmitted: 'OnboardingSosSubmitted',
  onboardingRealTimeLocation: 'OnboardingRealTimeLocation',
  onboardingRealTimeLocationSubmitted: 'OnboardingRealTimeLocationSubmitted',
  onboardingDevices: 'OnboardingDevices',
  onboardingDevicesSubmitted: 'OnboardingDevicesSubmitted',
  onboardingCheckIn: 'OnboardingCheckIn',
  onboardingCheckInSubmitted: 'OnboardingCheckInSubmitted',
  onboardingIosAndroid: 'OnboardingIosAndroid',
  onboardingIosAndroidSubmitted: 'OnboardingIosAndroidSubmitted',
  onboardingAR: 'OnboardingAR',
  onboardingARSubmitted: 'OnboardingARSubmitted',
  onboardingChat: 'OnboardingChat',
  onboardingChatSubmitted: 'OnboardingChatSubmitted',
  onboardingBattery: 'OnboardingBattery',
  onboardingBatterySubmitted: 'OnboardingBatterySubmitted',
  onboardingTrack: 'OnboardingTrack',
  onboardingTrackSubmitted: 'OnboardingTrackSubmitted',
  onboardingPlace: 'OnboardingPlace',
  onboardingPlaceSubmitted: 'OnboardingPlaceSubmitted',
  onboardingAddPlace: 'OnboardingAddPlace',
  onboardingAddPlaceSubmitted: 'OnboardingAddPlaceSubmitted',
  onboardingAddPlaceSkip: 'OnboardingAddPlaceSkip',
  onboardingPlaceaddedSubmitted: 'OnboardingPlaceaddedSubmitted',
  onboardingCreatingProfile: 'OnboardingCreatingProfile',
  onboardingPaywallShown: 'OnboardingPaywallShown',
  onboardingPaywallChosen: 'OnboardingPaywallChosen',
  onboardingCheckoutShown: 'OnboardingCheckoutShown',
  cardMethodChosen: 'CardCheckoutChosen',
  subBuyTapped: 'SubBuyTapped',
  subBuyClosed: 'PaywallClosed',
  subscribed: 'PaywallSubscribed',
  paymentAnimationScreen: 'PaymentAnimationScreen',
  onboardingPaymentDecline: 'OnboardingPaymentDecline',
  onboardingDeclineTryAgain: 'OnboardingDeclineTryAgain',
  onboardingSuccessShown: 'OnboardingSuccessShown',
  onboardingDone: 'OnboardingDone',
};
