import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import { sendAnalyticsEvents } from '../../analytics/common';
import { EVENTS } from '../../analytics/events';
import AppButton from '../../components/AppButton';
import AppContainer from '../../components/AppContainer';
import { selectEventsData } from '../../store/events';
import { selectDeeplink, selectEmail } from '../../store/signup';

const isArabic = navigator.language.startsWith('ar');
const textAlign = isArabic ? 'right' : 'left';
const APP_ONE_LINK = 'https://gzl.onelink.me/UpQ4';

const GeozillaIcon = styled.div`
  width: 124px;
  height: 160px;
  background-image: url('../assets/checkout/GZ_Logo.svg');
`;

const ImgWrapper = styled.div`
  margin-bottom: 48px;
  display: flex;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;

  @media screen and (max-width: 450px) {
    align-items: ${`flex-${isArabic ? 'end' : 'start'}`};
  }
`;

const Title = styled.h1`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.font_32};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_28};
  color: ${(props) => props.theme.colors.main};
  text-align: center;
  margin: 0 0 24px 0;

  @media screen and (max-width: 450px) {
    text-align: ${textAlign};
  }
`;

const InstructionsWrapper = styled.div`
  max-width: 330px;
  margin-bottom: 40px;
`;

const ItemRow = styled.div`
  display: flex;
  flex-direction: ${`row${isArabic ? '-reverse' : ''}`};
  justify-content: center;
  width: 100%;
  text-align: ${textAlign};

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const ItemNumber = styled.div`
  width: 7%;
  font-size: ${(props) => props.theme.fontSizes.font_18};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_21};
  color: ${(props) => props.theme.colors.main};
  font-weight: ${(props) => props.theme.fontWeights.light};
  ${isArabic && 'direction: rtl;'}
`;

const ItemStepDescription = styled.div`
  width: 85%;
  font-size: ${(props) => props.theme.fontSizes.font_18};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_21};
  color: ${(props) => props.theme.colors.main};
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  > div {
    margin-top: 10px;
    overflow-wrap: break-word;
    display: inline-block;
    width: 100%;
    font-style: italic;
  }
`;

const Loader = styled.div`
  width: 40px;
  height: 100%;
  background-image: url('../assets/loader.gif');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 150%;
  margin: auto;
`;

const SuccessScreen = () => {
  const { t } = useTranslation();
  const eventsData = useSelector(selectEventsData);
  const email = useSelector(selectEmail);
  const deeplinkValue = useSelector(selectDeeplink);
  const theme = useTheme();
  const [deeplink, setDeeplink] = useState('');

  useEffect(
    () => sendAnalyticsEvents(EVENTS.onboardingSuccessShown, eventsData),
    [eventsData]
  );

  const onContinueClick = () => {
    if (!deeplink) return;
    sendAnalyticsEvents(EVENTS.onboardingDone, eventsData);
    window.open(deeplink);
  };

  useEffect(() => {
    const timeout = setTimeout(() => setDeeplink(APP_ONE_LINK), 7000);
    if (!deeplinkValue) return;
    clearTimeout(timeout);
    setDeeplink(deeplinkValue);
  }, [deeplinkValue]);

  return (
    <AppContainer
      customJustify={'center'}
      customBgColor={theme.colors.gradient_bg}
    >
      <ContentWrapper>
        <ImgWrapper>
          <GeozillaIcon />
        </ImgWrapper>
        <Title>{t('your_plans_awaits')}</Title>
        <InstructionsWrapper>
          <ItemRow>
            <ItemNumber>1.</ItemNumber>
            <ItemStepDescription>{t('download_app')}</ItemStepDescription>
          </ItemRow>
          <ItemRow>
            <ItemNumber>2.</ItemNumber>
            <ItemStepDescription>
              {t('sign_in_with')} <div>{decodeURIComponent(email)}</div>
            </ItemStepDescription>
          </ItemRow>
          <ItemRow>
            <ItemNumber>3.</ItemNumber>
            <ItemStepDescription>{t('enjoy_membership')}</ItemStepDescription>
          </ItemRow>
        </InstructionsWrapper>
      </ContentWrapper>
      <AppButton
        customColor={theme.colors.main}
        customFontColor={theme.colors.btn}
        onClick={onContinueClick}
        bottomPosition={'24px'}
        customBeforeBg={'none'}
        customId={'success-button'}
        style={{ cursor: !deeplink ? 'not-allowed' : 'pointer' }}
      >
        {!deeplink ? <Loader /> : t('got_it')}
      </AppButton>
      <input type="hidden" id="end-of-onboarding" />
    </AppContainer>
  );
};

export default SuccessScreen;
